angular.module('BillPay')
    .provider('AppStates', function () {

        var _appState = 'app',

            _baseStates =  {
                app: _appState,
                default: _appState + '.default',
                payment: _appState + '.payment',
                receipt: _appState + '.receipt'
            },

            states = {
                root: _baseStates.app,
                core: {
                    root: _baseStates.app,
                    error: _baseStates.app + '.error'
                },
                default: {
                    root: _baseStates.default,
                    feedback: _baseStates.default + '.feedback',
                    link: _baseStates.default + '.link'
                },
                payment: {
                    root: _baseStates.payment,
                    amount: _baseStates.payment + '.amount',
                    date: _baseStates.payment + '.date',
                    method: _baseStates.payment + '.method',
                    address: _baseStates.payment + '.address',
                    patientinfo: _baseStates.payment + '.patientinfo',
                    contact: _baseStates.payment + '.contact',
                    confirm: _baseStates.payment + '.confirm'
                },
                receipt: {
                    root: _baseStates.receipt,
                    view: _baseStates.receipt + '.view'
                }
            };

        return {
            get: function () {
                return states;
            },
            $get: function () {
                return states;
            }
        };
    });
