angular.module('BillPay')
    .config(function ($stateProvider, AppPathsProvider, AppStatesProvider) {

        var states = AppStatesProvider.get(),
            paths = AppPathsProvider.get();

        $stateProvider
            .state(states.default.root, {
                url: '',
                templateUrl: paths.default.root,
                controller: 'LandingCtrl',
                resolve: {
                    Provider: function (ProvidersService) {
                        return ProvidersService.getProvider();
                    }
                },
                onEnter: function ($rootScope) {
                    $rootScope.showNavigation(false);
                }
            })
            .state(states.default.feedback, {
                url: 'feedback/:token/?:score',
                templateUrl: paths.default.feedback,
                controller: 'FeedbackCtrl'
            })
            .state(states.default.link, {
                url: 'link/:token/?:source',
                controller: 'LinkTokenCtrl',
                resolve: {}
            });
    });
