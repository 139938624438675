angular.module('BillPay')
    .controller('FeedbackCtrl', function($scope, $filter, $log, $stateParams, Base64Service, FeedbackService) {

        $scope.forms = {
            feedbackForm: {}
        };

        var _reset = function() {

            $scope.feedback = {
                comment: ''
            };
            if ($scope.forms && $scope.forms.feedbackForm && $scope.forms.feedbackForm.$dirty) {
                $scope.forms.feedbackForm.$setPristine();
            }
            $scope.submitting = false;
            $scope.showFeedback = false;
            $scope.buttonText = $filter('translate')('actions.sendFeedback');
        };

        $scope.initFeedbackModal = function () {
            _reset();

            $scope.feedback.feedbackToken = $stateParams.token;
            var score = ($stateParams.score === '1' || $stateParams.score === '0') ? parseInt($stateParams.score) : 1; //default to happy

            FeedbackService.addFeedback($scope.feedback.feedbackToken, score)
                .then(function (data) {
                    $scope.feedback.feedbackId = data.feedbackId;
                    $scope.feedbackSource = data.feedbackSource;
                    $scope.providerName = data.providerName;

                    $scope.translationData = {
                        providerName: $scope.providerName
                    };

                    $scope.setScore(parseInt(score));
                    $scope.showFeedback = true;
                });
        };

        $scope.setScore = function(newScore) {
            $scope.feedback.happySelected = newScore === 1;

            if ($scope.feedbackSource === 'guestReceiptLink') {
                $scope.feedbackTitle = $filter('translate')('dialogs.feedbackTitleReceiptLink');
                $scope.commentPrompt = $scope.feedback.happySelected ? $filter('translate')('dialogs.feedbackSubtitlePaymentHappy') : $filter('translate')('dialogs.feedbackSubtitlePaymentSad');
            }
        };

        $scope.cancel = function() {
            _reset();
            $scope.goDefaultState();
        };

        $scope.sendFeedback = function() {
            if ($scope.forms.feedbackForm.$invalid || $scope.submitting) {
                return;
            }

            $scope.submitting = true;
            $scope.buttonText = $filter('translate')('actions.sendingFeedback');

            //no error handling below.  we have no legit situations where this call would fail.  if it does, we'll pretend that it went through and clean-up after ourselves
            FeedbackService.editFeedback($scope.feedback.feedbackId, $scope.feedback.feedbackToken, ($scope.feedback.happySelected ? 1 : 0), $scope.feedback.comment)
                .finally(function(){
                    _reset();

                    $scope.goDefaultState();

                    // overlay the thank you modal
                    $scope.$emit('simpleModal:showPrompt',{
                        header: $filter('translate')('dialogs.thanksForFeedback'),
                        intent: 'success'
                    });
                });
        };
    });
