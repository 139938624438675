angular.module('BillPay')
    .factory('PaymentFormModel', function ($filter, CoreModel, PAYMENT_METHOD, ACCEPTED_CREDIT, CardService, _) {

        return CoreModel('payment-form', {

            deserialize: function (data) {
                this.firstName = data.firstName;
                this.lastName = data.lastName;
                this.formType = data.formType;
                this.cardType = data.cardType;
                this.cardNumber = data.cardNumber;
                this.expDate = data.expDate;
                this.cvv = data.cvv;
                this.zip = data.zip;
                this.bankName = data.bankName;
                this.routingNumber = data.routingNumber;
                this.accountNumber = data.accountNumber;
                this.last4Digits = data.last4Digits;
            },

            serialize: function () {
                return {
                    firstName: this.firstName || '',
                    lastName: this.lastName || '',
                    formType: this.formType || '',
                    last4Digits: this.last4Digits || (this.getLastFourOfCurrentMethod() || ''),
                    cardType: this.isCredit() ? this.cardType : '',
                    expDate: this.expDate || '',
                    routingNumber: this.routingNumber || '',
                    bankName: this.isECheck() ? this.bankName : ''
                };
            },

            getValidatedFields: function () {
                return ['firstName', 'lastName', 'formType'];
            },

            // Validations

            validateFirstName: function (val) {
                return angular.isString(val) && val.length > 0;
            },

            validateLastName: function (val) {
                return angular.isString(val) && val.length > 0;
            },

            validateFormType: function (val) {
                return angular.isString(val) && _.values(PAYMENT_METHOD).indexOf(val) >= 0;
            },

            validateCardType: function (val) {
                return angular.isString(val) && ACCEPTED_CREDIT.indexOf(val) >= 0;
            },

            validateCardNumber: function (val) {
                var issuer = CardService.getIssuer(val);
                return angular.isString(val) && val.length > 5
                    && val.length < 20 && ACCEPTED_CREDIT.indexOf(issuer) >= 0;
            },

            validateExpDate: function (val) {
                return angular.isString(val) && val.length === 4
                    && !CardService.isExpired(val);
            },

            validateBankName: function (val) {
                return angular.isString(val) && val !== '';
            },

            validateRoutingNumber: function (val) {
                return angular.isString(val) && val.length === 9;
            },

            validateAccountNumber: function (val) {
                return angular.isString(val) && val.length >= 4
                    && val.length <= 25;
            },

            // Util

            isCredit: function () {
                return this.formType === PAYMENT_METHOD.CREDIT;
            },

            isECheck: function () {
                return this.formType === PAYMENT_METHOD.ECHECK;
            },

            getLastFourOfCurrentMethod: function () {
                if (angular.isUndefined(this.cardNumber) && angular.isUndefined(this.accountNumber)) {
                    return;
                }
                return (this.isCredit() ? this.cardNumber : this.accountNumber).toString().slice(-4);
            },

            toString: function () {
                return $filter('methodDesc')(this.serialize());
            },

            toShortString: function () {
                return $filter('methodDesc')(this.serialize(), 'short', '&nbsp;');
            }

        });
    });
