angular.module('BillPay')
    .controller('PaymentCtrl', function ($scope, $rootScope, $filter, $log, $timeout,
                                         Provider, Payment, ProviderPaymentService, AppStates, _, moment) {

        $scope.Provider = Provider;
        $scope.Payment = Payment;
        $scope.forms = {};

        $scope.Payment.isPremiumPayment = $rootScope.isPremiumPayment;
        if ($scope.Payment.isPremiumPayment) {
            $scope.Payment.paymentDate = moment();
            $scope.Payment.isPremiumPayment = true;
            $scope.notificationText = $filter('translate')('notifications.questionAboutInvoice', { phone: Provider.phone });
        }

        var logger = $log.getInstance('payment-ctrl'),
            visitedStates = [],

            _isCompletedState = function (state) {
                switch (state) {
                    case AppStates.payment.amount:
                        return Payment.validate('amount');
                    case AppStates.payment.date:
                        return Payment.validate('paymentDate');
                    case AppStates.payment.method:
                        return Payment.validate('method') && Payment.validate('paymentForm');
                    case AppStates.payment.address:
                        return $rootScope.isEcashiering || Payment.validate('streetAddress') && (Payment.isInternational || Payment.validate('zip'));
                    case AppStates.payment.patientinfo:
                        return $rootScope.isEcashiering || $rootScope.isGuestPay || $rootScope.isPremiumPayment
                            || Payment.validate('patientFirstName') && Payment.validate('patientLastName')
                            && Payment.validate('patientPhone') && Payment.validate('patientDOB');
                    case AppStates.payment.contact:
                        //Email is optional for ecashiering flow and may be autofilled, so consider it complete
                        //if the page has been visited and a valid email is entered or it's blank
                        return ($rootScope.isEcashiering && !Payment.emailAddress
                            || Payment.validate('emailAddress'))
                            && ($scope.isState(state) || !!~visitedStates.indexOf(state));
                    case AppStates.payment.confirm:
                        return Payment.isValid() && Payment.isCompleted();
                }
                return false;
            };

        logger.info('Initializing payment flow with', Payment);

        $scope.translationData = {
            todayDate: $filter('dateFormat')(new Date(), 'LL')
        };

        $scope.isActiveState = function (state) {
            return !!~visitedStates.indexOf(state) || _isCompletedState(state);
        };

        $scope.goToNextIncompleteState = function () {
            var state, numStates;
            for (state in AppStates.payment) {
                state = AppStates.payment[state];
                if (state !== AppStates.payment.root && !_isCompletedState(state)) {
                    if (!~visitedStates.indexOf(state)) visitedStates.push(state);
                    numStates = _.keys(AppStates.payment).length-1;
                    $scope.progress = (visitedStates.length / numStates) * 100;
                    return !$scope.isState(state) && $scope.goToState(state);
                } else if (state !== AppStates.payment.root) {
                    //If a page is complete, make sure it's considered visited
                    if (!~visitedStates.indexOf(state)) visitedStates.push(state);
                }
            }
        };

        $scope.continue = function (callback) {
            ProviderPaymentService.updatePayment(Payment);
            $scope.goToNextIncompleteState();
            if (angular.isFunction(callback)) {
                $timeout(callback, 10);
            }
        };

        $scope.$on('$stateChangeStart', function (e, toState, toStateParams, fromState, fromStateParams) {
            if (angular.isDefined(toState) && angular.isDefined(fromState)) {
                var paymentStates = _.values(AppStates.payment);
                $scope.reverseTransition = paymentStates.indexOf(toState.name) < paymentStates.indexOf(fromState.name);
            }
        });

    });
