angular.module('BillPay')
    .controller('PaymentMethodCtrl', function ($scope, Provider, Payment,
                                               PAYMENT_METHOD, CardService,
                                               ProviderPaymentService, PaymentFormModel,
                                               ServerStatusService, _) {
        $scope.PAYMENT_METHOD = PAYMENT_METHOD;

        $scope.statuses = {
            creditDown: !ServerStatusService.getPaymentStatus(PAYMENT_METHOD.CREDIT),
            echeckDown: !ServerStatusService.getPaymentStatus(PAYMENT_METHOD.ECHECK),
            allDown: !ServerStatusService.getPaymentStatus(PAYMENT_METHOD.ECHECK) &&
                     !ServerStatusService.getPaymentStatus(PAYMENT_METHOD.CREDIT)
        };

        var defaultFormType = Provider.allowsPaymentType(PAYMENT_METHOD.ECHECK) ?
                                PAYMENT_METHOD.ECHECK : PAYMENT_METHOD.CREDIT;

        $scope.formType = Payment.paymentForm.validate('formType') ?
                            Payment.paymentForm.formType : defaultFormType;

        $scope.sharedForm = {
            firstName: Payment.paymentForm.firstName || '',
            lastName: Payment.paymentForm.lastName || ''
        };

        $scope.creditForm = {
            icon: CardService.getIcon(),
            cardNumber: Payment.paymentForm.cardNumber,
            cardType: Payment.paymentForm.cardType,
            expDate: Payment.paymentForm.expDate || '',
            cvv: Payment.paymentForm.cvv || '',
            zip: Payment.paymentForm.zip || ''
        };

        $scope.eCheckForm = {
            routingNumber: Payment.paymentForm.routingNumber,
            bankName: Payment.paymentForm.bankName,
            accountNumber: Payment.paymentForm.accountNumber,
            accountNumberConfirm: Payment.paymentForm.accountNumber
        };

        $scope.scannedForm = {};

        $scope.translationData.paymentExpMin = Payment.paymentDate.format('MM/YY');

        $scope.creditHelpers = {
            mapScannedForm: function () {
                if (angular.isString($scope.scannedForm.name)) {
                    var nameParts = $scope.scannedForm.name.split(' ');
                    $scope.sharedForm.firstName = _.dropRight(nameParts, 1).join(' ');
                    $scope.sharedForm.lastName = _.last(nameParts);
                }
                if (angular.isString($scope.scannedForm.expDate.month)
                    && angular.isString($scope.scannedForm.expDate.year)) {
                    $scope.creditForm.expDate = [
                        $scope.scannedForm.expDate.month,
                        $scope.scannedForm.expDate.year.slice(-2)
                    ].join('');
                    // Change this, shouldn't ever interact with DOM in controller
                    angular.element(document.getElementById('cc_number')).triggerHandler('format');
                }
            },
            determineCardType: function (cardNo) {
                $scope.creditForm.cardType = CardService.getIssuer(cardNo);
                $scope.creditForm.icon = CardService.getIcon($scope.creditForm.cardType);
            }
        };

        $scope.eCheckHelpers = {
            setBankName: function (name) {
                $scope.eCheckForm.bankName = name;
            },
            setRoutingNumber: function (number) {
                $scope.eCheckForm.routingNumber = number;
            }
        };

        $scope.setPaymentForm = function (formType, form) {
            var validCreditForm = formType === PAYMENT_METHOD.CREDIT && !$scope.forms.ccForm.$invalid,
                validECheckForm = formType === PAYMENT_METHOD.ECHECK && !($scope.forms.echeckForm.$invalid
                                    || $scope.forms.echeckForm.routing.$pending);

            if (!(validCreditForm || validECheckForm)) return;

            angular.extend(form, $scope.sharedForm);
            form.formType = formType;

            ProviderPaymentService.validateProviderPaymentMethod(formType).then(
                function (isValid) {
                    if (isValid && Payment.set('method', formType)
                        && Payment.set('paymentForm', new PaymentFormModel(form))) {
                        $scope.continue();
                    }
                }
            );
        };
    });
