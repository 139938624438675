angular.module('BillPay')
    .controller('LandingCtrl', function ($scope, $rootScope, $filter, AppStates,
                                        $translate, ProviderPaymentService, Provider, ErrorsService) {

        if (!Provider.billPayEnabled && !Provider.guestPayEnabled && !Provider.mobilePayEnabled) {
            $scope.goToState(AppStates.core.error, {
                'status': 404,
                'statusText': 'Not Found',
                'errorCode': ErrorsService.resolve({'errorCode': 'PROVIDER_NOT_FOUND'})
            });
        } else if (!Provider.billPayEnabled) {
            $scope.goToState(AppStates.core.error, {
                'status': $filter('translate')('errors.oops'),
                'statusText': $filter('translate')('errors.invalidFeature'),
                'errorCode': Provider.guestPayEnabled ? 'invalidFeaturePromptReturn' : 'invalidFeaturePromptContact',
                'additionalData': Provider
            });
        }

        $scope.Provider = Provider;
        $rootScope.isProviderBranded = Provider.isBranded;
        $scope.submitting = false;
        $scope.actionText = 'actions.begin';

        $translate(['meta.title', 'meta.description'], {'providerName': Provider.name}).then(function (translations) {
            $rootScope.metaTitle = translations['meta.title'];
            $rootScope.metaDescription = translations['meta.description'];
        });
        
        $scope.marketingBlurb = $filter('translate')('landing.marketingBlurb', {
            providerName: Provider.name
        });

        $scope.accountInvalidMessage = Provider.accountNumberErrorMessage ? Provider.accountNumberErrorMessage : $filter('translate')('errors.accountInvalid');
        $scope.memberIdInvalidMessage = Provider.memberIdErrorMessage ? Provider.memberIdErrorMessage : $filter('translate')('errors.memberIdInvalid', { memberIdLabel: Provider.memberIdLabel });
        $scope.invoiceNumberInvalidMessage = Provider.invoiceNumberErrorMessage ? Provider.invoiceNumberErrorMessage : $filter('translate')('errors.invoiceNumberInvalid', { invoiceNumberLabel: Provider.invoiceNumberLabel });
        $scope.memberIdRequiredMessage = $filter('translate')('errors.memberIdRequired', { memberIdLabel: Provider.memberIdLabel });
        $scope.invoiceNumberRequiredMessage = $filter('translate')('errors.invoiceNumberRequired', { invoiceNumberLabel: Provider.invoiceNumberLabel });
        $scope.notFoundMessage = $filter('translate')('errors.notFound', { invoiceNumberLabel: Provider.invoiceNumberLabel, phone: Provider.phone });
        $scope.noBalanceMessage = $filter('translate')('errors.noBalance', { invoiceNumberLabel: Provider.invoiceNumberLabel });

        $scope.clearSearchErrors = function() {
            $scope.searchErrors = {
                notFound: false,
                noBalance: false,
                rateLimit: false
            };
        };
        $scope.clearSearchErrors();

        $scope.belowMinError = $filter('translate')('errors.belowMinAmount', {
            minAmount: $filter('currency')(Provider.limits.minimumPaymentAmount)
        });

        $scope.startPayment = function () {
            if ($scope.submitting || $scope.accountAmountForm.$invalid) {
                return;
            }
            $scope.submitting = true;
            $scope.actionText = 'actions.loading';
            if (Provider.premiumPaymentsEnabled) {
                ProviderPaymentService.createProviderPaymentFromInvoice($scope.accountAmountForm.memberId.$modelValue, $scope.accountAmountForm.invoiceNumber.$modelValue)
                    .then(function () {
                        $scope.clearSearchErrors();
                        $rootScope.isPremiumPayment = true;
                        $scope.goToState(AppStates.payment.amount);
                        $scope.submitting = false;
                        $scope.actionText = 'actions.begin';
                    }).catch(function(errorType) {
                        $scope.clearSearchErrors();
                        $scope.searchErrors[errorType] = true;
                        $scope.submitting = false;
                        $scope.actionText = 'actions.begin';
                    });
            } else {
                ProviderPaymentService.createProviderPaymentFromBill($scope.accountAmountForm.accountNumber.$modelValue, $scope.accountAmountForm.billAmount.$modelValue, $rootScope.isEcashiering, null)
                    .then(function () {
                        $rootScope.isPremiumPayment = false;
                        $scope.goToState(AppStates.payment.amount);
                        $scope.submitting = false;
                        $scope.actionText = 'actions.begin';
                    });
            }
        };

        $scope.learnMore = function() {
            $scope.openTab('https://www.patientco.com/happy-patients/');
        };
    });
