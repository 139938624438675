
/**
endpoints are the set of constant values that determine our
backend service endpoints. These endpoints are used in unit tests,
backendless development listeners, and $http calls.
    - controls versioning
    - follows pattern that is consumable by the
        ngMockE2E endpoint selection system

NOTE: do not use endpoints directly unless it's being used in
backend-listeners (mocking) or in $resources
*/
angular.module('BillPay').factory('endpoints', ['Config', function (Config) {

    var API_VERSION = 'v1',
        API_URI = Config.apiUrl + '/services/' + API_VERSION + '/',

        /* istanbul ignore next */
        ep = function endpointFactory( endpointUrl, endpointMatcher ){
            return {
                url: API_URI + endpointUrl,
                match: endpointMatcher || endpointUrl
            };
        },

        endpoints = {};

    endpoints.paymentForms = {};
    endpoints.paymentForms.routing = ep('banks/:routingNumber', /\/banks\/\d{9}$/);

    endpoints.feedback = {};
    endpoints.feedback.insert = ep('feedback', /\/feedback/);
    endpoints.feedback.update = ep('feedback/:id', /\/feedback\/(\d+)$/);

    endpoints.payments = {};
    endpoints.payments.prepGateway = ep('payments', /\/payments/);
    endpoints.payments.confirm = ep('payments/:paymentId/confirm', /\/payments\/(\d+)\/confirm$/);

    endpoints.providers = {};
    endpoints.providers.primary = ep('providers/:urlSlug', /\/providers(\/[0-9a-zA-Z]+)?$/);
    endpoints.providers.getInvoice = ep('providers/:urlSlug/invoice/:invoice', /\/providers(\/[0-9a-zA-Z]+)\/invoice$/);

    // Currently, we build the provider logo url based on the provider id.
    // But, since we are not using, $http, we can't intercept it and thus
    // don't need a matcher
    endpoints.providers.logo = ep('providers/:urlSlug/logo', /\/providers\/([0-9a-zA-Z]+)\/logo$/);
    endpoints.providers.theme = ep('providers/:urlSlug/theme', /\/providers\/([0-9a-zA-Z]+)\/theme/);
    endpoints.providers.favicon = ep('providers/:urlSlug/favicon', /\/providers\/([0-9a-zA-Z]+)\/favicon/);

    endpoints.token = {};
    endpoints.token.contents = ep('token/:token', /\/token\/(.*?)$/);

    return endpoints;

}]);
