angular.module('BillPay')
    .controller('ReceiptCtrl', function ($scope, $rootScope, $stateParams, $filter, PAYMENT_STATE, $window) {

        $scope.showThankYouModal = function () {
            if (angular.isUndefined($stateParams.payment)) {
                $scope.goDefaultState();
                return;
            }

            $scope.Payment = $stateParams.payment;
            $scope.$emit('simpleModal:showPrompt', {
                header: $filter('translate')('dialogs.thanksForPayment' + ($rootScope.isScheduledCart ? 'Info' : '')),
                subcontent: $scope.Payment.emailAddress ? $filter('translate')('dialogs.emailSentTo', { email: $scope.Payment.emailAddress }) : '',
                intent: 'success',
                actions: [{
                    label: $filter('translate')('actions.openReceipt'),
                    clickHandler: function(){
                        $scope.$emit('simpleModel:hidePrompt');
                    }
                }]
            });
        };

        $scope.setUIVariables = function () {
            $scope.translations = {};

            var formattedAmount = $filter('currency')($rootScope.isEcashiering ? $scope.Payment.cartAmount : $scope.Payment.amount);
            if ($scope.Payment.status === PAYMENT_STATE.PROCESSED) {
                $scope.translations.receiptState = $filter('translate')('receipt');
                $scope.translations.amountStatus = $filter('translate')('labels.argPaid' , { amount: formattedAmount });
                $scope.translations.callOutAmountStatus = $filter('translate')('receipt.argPaidCallout' , { amount: formattedAmount });
                $scope.translations.transactionId = $filter('translate')('receipt.transactionIdArg', { transactionId: $scope.Payment.transactionId });
            } else if ($scope.Payment.status === PAYMENT_STATE.SCHEDULED) {
                $scope.translations.receiptState = $filter('translate')('labels.futurePayment');
                $scope.translations.amountStatus = $filter('translate')( 'labels.argScheduled', { amount: formattedAmount});
                $scope.translations.callOutAmountStatus = $filter('translate')('receipt.argScheduledCallOut', { amount: formattedAmount});
                $scope.transactionId = '';
            }

            if ($rootScope.isEcashiering) {
                $scope.translations.paymentType = $filter('translate')('receipt.payment');
            } else if ($rootScope.isPremiumPayment) {
                $scope.translations.paymentType = $filter('translate')('receipt.premiumPayment');
            } else {
                $scope.translations.paymentType = $filter('translate')('receipt.balancePayment');
            }
        };

        $scope.print = function(){
            $window.print();
        };

    })
    .controller('ReceiptViewCtrl', function ($rootScope, $scope, Provider) {
        $scope.Provider = Provider;

        $rootScope.goToPayAnotherBill = function() {
            if ($rootScope.isGuestPay) {
                //go back to pw homepage
                $scope.redirect($scope.Provider.patientWalletUrl);
            } else {
                $scope.goDefaultState();
            }
        };
    });
