angular.module('BillPay')
    .controller('NavigationCtrl', function ($scope, $rootScope, $state, ProvidersService) {
        ProvidersService.getProvider().then(function (Provider) {
            $scope.Provider = Provider;
        });

        $scope.showMobileBackButton = function() {
            return (!$rootScope.isEcashiering && !$rootScope.isGuestPay) || !$state.is('app.receipt.view');
        };
    });
