angular.module('BillPay')
    .controller('LinkTokenCtrl', function ($scope, $rootScope, $filter, $stateParams,
        $timeout, AppStates, ErrorsService, TokenService, ProviderPaymentService, moment,
        Provider, PAYMENT_METHOD) {

        $rootScope.token = $stateParams.token;
        $rootScope.source = $stateParams.source;

        switch ($stateParams.source) {
            case 'guestPay':
                $scope.invalidTokenError = 'tokenExpiredGuestPay';
                break;
            case 'mobilePay':
                $scope.invalidTokenError = 'tokenExpiredMobilePay';
                break;
            default:
                $scope.invalidTokenError = 'tokenExpiredGeneric';
        }

        /*****
         * Show the loading indicator while we call the token decryption service
         *****/
        // Hide the container to keep the page from flashing
        document.querySelector('.view-layout-transition').style.display = 'none';
        $timeout(function(){
            $rootScope.$broadcast('showLoadingIndicator');
            // Then, reshow the page once we've reactivated the loading indicator
            $timeout(function(){
                document.querySelector('.view-layout-transition').style.display = 'block';
            }, 0);
        }, 0);

        /*****
         * Call the token decryption service
         *****/
        TokenService.getPayload($rootScope.token).then(function(payload){
            $scope.payload = payload;

            if($rootScope.providerSlug === $scope.payload.providerSlug.toLowerCase()
                && angular.isNumber($scope.payload.amount)
                && $scope.payload.amount > 0
                && angular.isString($scope.payload.accountNumber)
                && $scope.payload.accountNumber.length > 0
            ) {
                // Check if payment is unconfirmed, if not go to the error page
                if ($scope.payload.isUnconfirmed === false) {
                    $scope.goToState(AppStates.core.error, {
                        'status': $filter('translate')('errors.oops'),
                        'statusText': $filter('translate')('errors.urlExpired'),
                        'errorCode': 'paymentExpired'
                    });
                } else {
                    var isEcashiering = ($scope.payload.paymentId && angular.isNumber($scope.payload.paymentId));
                    ProviderPaymentService.createProviderPaymentFromBill($scope.payload.accountNumber, $scope.payload.amount, isEcashiering, $scope.payload.subAccountNumber || null)
                        .then(function (payment) {
                            $rootScope.isEcashiering = isEcashiering;
                            if ($rootScope.isEcashiering) {
                                $rootScope.isGuestPay = false;
                                payment.set('isEcashiering', true);
                                payment.set('savePaymentForm', $scope.payload.savePaymentForm);
                                payment.set('id', $scope.payload.paymentId);
                                payment.set('amount', $scope.payload.amount);
                                payment.set('paymentDate', moment());
                                payment.set('emailAddress', $scope.payload.email);
                                $rootScope.isScheduledCart = $scope.payload.isScheduledCart;
                                $rootScope.webhook = decodeURIComponent($scope.payload.webhook);
                                Provider.disallowPaymentType(PAYMENT_METHOD.ECHECK);
                                $scope.goToState(AppStates.payment.method);
                            } else {
                                $rootScope.isGuestPay = true;
                                payment.set('isGuestPay', true);
                                $scope.goToState(AppStates.payment.amount);
                            }
                        }, function(err){
                            $scope.goToState(AppStates.core.error, {
                                'status': 500,
                                'statusText': $filter('translate')('errors.unexpectedError'),
                                'errorCode': ErrorsService.resolve({'errorCode': 'UNEXPECTED_ERROR'})
                            });
                        });
                }
            }else{
                $scope.goToState(AppStates.core.error, {
                    'status': 500,
                    'statusText': $filter('translate')('errors.unexpectedError'),
                    'errorCode': ErrorsService.resolve({'errorCode': 'UNEXPECTED_ERROR'})
                });
            }
        }, function(err){
            $scope.payload = false;
            $scope.goToState(AppStates.core.error, {
                'status': $filter('translate')('errors.oops'),
                'statusText': $filter('translate')('errors.urlExpired'),
                'errorCode': $scope.invalidTokenError,
                'additionalData': Provider
            });
        }).finally(function(){
            // Last, hide the loading indicator
            $timeout(function(){
                $rootScope.$broadcast('hideLoadingIndicator');
            }, 0);
        });
    });
