angular.module('BillPay')
    .controller('PaymentAddressCtrl', function ($scope, $filter, Payment, Provider) {
        $scope.address = {
            isInternational: Payment.isInternational || false,
            streetAddress: Payment.streetAddress,
            zip: Payment.zip || null,
            postalCode: Payment.postalCode || null
        };
        $scope.allowInternationalPayments = Provider.allowInternationalPayments;
        $scope.setIsInternational = function(isInternational) {
            $scope.address.isInternational = isInternational;
        };

        $scope.billingAddressQuestion = $filter('translate')('payment.billing.question', {
            methodSummary : Payment.paymentForm.toShortString()
        });

        $scope.setAddress = function (streetAddress, isInternational, zip, postalCode) {
            if ($scope.forms.billing.$invalid) return;
            Payment.set('isInternational', isInternational);
            if (isInternational) {
                Payment.zip = null;
                if (Payment.set('streetAddress', streetAddress) && Payment.set('postalCode', postalCode)) {
                    $scope.continue();
                }
            } else {
                Payment.postalCode = null;
                if (Payment.set('streetAddress', streetAddress) && Payment.set('zip', zip)) {
                    $scope.continue();
                }
            }
        };
    });
