angular.module('BillPay')
    .config(function ($stateProvider, AppStatesProvider, AppPathsProvider) {

        var states = AppStatesProvider.get(),
            paths = AppPathsProvider.get();

        $stateProvider
            .state(states.payment.root, {
                url: 'payment/',
                templateUrl: paths.payment.root,
                controller: 'PaymentCtrl',
                abstract: true,
                resolve: {
                    Provider: function (ProvidersService) {
                        return ProvidersService.getProvider();
                    },
                    Payment: function (ProviderPaymentService) {
                        return ProviderPaymentService.getCurrentProviderPayment();
                    }
                },
                onEnter: function ($rootScope) {
                    $rootScope.showNavigation(true);
                }
            })
            .state(states.payment.amount, {
                url: '',
                templateUrl: paths.payment.amount,
                controller: 'PaymentAmountCtrl',
                resolve: {}
            })
            .state(states.payment.date, {
                url: 'date/',
                templateUrl: paths.payment.date,
                controller: 'PaymentDateCtrl',
                resolve: {}
            })
            .state(states.payment.method, {
                url: 'method/',
                templateUrl: paths.payment.method,
                controller: 'PaymentMethodCtrl',
                resolve: {}
            })
            .state(states.payment.address, {
                url: 'address/',
                templateUrl: paths.payment.address,
                controller: 'PaymentAddressCtrl',
                resolve: {}
            })
            .state(states.payment.patientinfo, {
                url: 'patientinfo/',
                templateUrl: paths.payment.patientinfo,
                controller: 'PaymentPatientInfoCtrl',
                resolve: {}
            })
            .state(states.payment.contact, {
                url: 'contact/',
                templateUrl: paths.payment.contact,
                controller: 'PaymentContactCtrl',
                resolve: {}
            })
            .state(states.payment.confirm, {
                url: 'confirm/',
                templateUrl: paths.payment.confirm,
                controller: 'PaymentConfirmCtrl',
                resolve: {}
            });
    });
