angular.module('BillPay').factory('FeedbackService', function($q, $resource, endpoints){

    var Feedback = $resource(endpoints.feedback.insert.url, null, {
        feedbackInsert: {
            method: 'POST',
            url: endpoints.feedback.insert.url
        },
        feedbackUpdate: {
            method: 'PUT',
            url: endpoints.feedback.update.url,
            params: {
                id: '@id'
            }
        }
    });

    return {
        addFeedback: function(token, score){
            return Feedback.feedbackInsert({token: token, score: score}).$promise.then(function(resp){
                if(resp && resp.hasData()){
                    return resp.getData();
                }
                return $q.reject(resp);
            });
        },

        editFeedback: function(id, token, score, comment){
            return Feedback.feedbackUpdate({id: id, token: token, score: score, comment: comment}).$promise;
        }
    };

});
