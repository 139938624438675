angular.module('BillPay')
    .controller('AppCtrl', function ($state, $scope, $rootScope, $window, $filter,
                                    ErrorsService, $log, AppPaths, AppStates,
                                    OverlayService, $timeout, Config, _, endpoints) {

        $scope.AppPaths = AppPaths;
        $scope.AppStates = AppStates;
        $scope.overlay = OverlayService;
        $scope.version = Config.version;

        var AppLogger = $log.getInstance('app'),
            safeRouteChange = true,
            now = new Date().getTime(),

            updateProviderSlug = function () {
                $rootScope.providerSlug = window.location.pathname.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
                AppLogger.debug('Provider slug ' + $scope.providerSlug + ' initialized');
            }; updateProviderSlug();

        $scope.whiteLabelCssUrl = endpoints.providers.theme.url.replace(':urlSlug', $rootScope.providerSlug) + '?nocache=' + now;

        $scope.getFaviconUrl = function(size) {
            return endpoints.providers.favicon.url.replace(':urlSlug', $rootScope.providerSlug) + '?&size=' + size + '&nocache=' + now;
        };

        $scope.goToState = function (stateName, stateParams, forcedReload) {
            safeRouteChange = true;
            if (stateName !== $state.current.name) {
                $state.go(stateName, stateParams);
            } else if (forcedReload) {
                $state.reload();
            } else {
                AppLogger.warn('Attempted to reroute to current state ' + stateName + '. State change rejected.');
                $rootScope.$broadcast('$stateChangeRejected');
            }
            return false;
        };

        $scope.goDefaultState = function () {
            updateProviderSlug();
            $scope.goToState(AppStates.default.root);
        };

        $scope.goBack = function () {
            $window.history.back();
        };

        $scope.isState = function (stateName) {
            return $state.current.name === stateName;
        };

        $scope.currentState = function () {
            return $state.current.name;
        };

        $scope.openTab = function (url) {
            $window.open(url, '_blank');
        };

        $scope.redirect = function (url) {
            $window.location.replace(url);
        };

        $rootScope.showNavigation = function (bool) {
            $scope.navigation = bool;
            $timeout(function () {
                $scope.$apply();
            });
        };

        $rootScope.$on('$stateChangeStart', function (e, toState, toStateParams, fromState, fromStateParams) {
            if(!safeRouteChange) {
                AppLogger.warn('Unsafe state change: ' + fromState.name + ' >> ' + toState.name + ' detected. ' +
                                 'Use $scope.goToState(stateName, stateParams) instead.');
            }
            safeRouteChange = false;
            AppLogger.debug('$stateChangeStart: ', fromState.name + ' >> ' + toState.name);

            // In guest pay, app.link.token is the first state, so fromState.name is empty on initial page load.
            // If the user tries to go back to app.link.token from another state, they are trying to go back to
            // where they came from, so let's just send them back.
            if (fromState.name && toState.name == 'app.link.token') {
                AppLogger.debug('Trying to back out of guest pay.');
                $window.history.back();
            }
        });

        $rootScope.$on('$stateChangeSuccess', function (e, toState, toStateParams, fromState, fromStateParams) {
            AppLogger.debug('$stateChangeSuccess: ', fromState.name + ' >> ' + toState.name);
        });

        $rootScope.$on('$stateChangeRejected', function () {
            AppLogger.debug('$stateChangeRejected');
        });

        $rootScope.$on('$stateChangeError', function (e, toState, toStateParams, fromState, fromStateParams, error) {

            AppLogger.error('$stateChangeError: ', fromState.name + ' >> ' + toState.name, error);

            var validError = angular.isDefined(error) && angular.isDefined(error.status) && _.isObject(error.data);

            if (validError) {
                error.errorCode = ErrorsService.resolve(error.data);
                error.additionalData = ErrorsService.getAdditionalData(error.data);
            } else if (error.status == 404) {
                error.statusText = 'Not Found';
                error.errorCode = ErrorsService.resolve({'errorCode': 'NOT_FOUND'});
            } else {
                error.status = 500;
                error.statusText = 'Unexpected Error';
                error.errorCode = ErrorsService.resolve({'errorCode': 'UNEXPECTED_ERROR'});
            }

            if (toState.name !== AppStates.default.root && $rootScope.providerSlug) {
                $scope.goDefaultState();
                AppLogger.warn('Has provider slug. Redirected to ' + AppStates.default.root);
            } else {
                $scope.goToState(AppStates.core.error, error);
            }
        });

        $rootScope.$on('$stateNotFound', function (e, toState) {
            AppLogger.warn('$stateNotFound: ' + toState.name);

            $scope.goToState(AppStates.core.error, {
                'status': 404,
                'statusText': 'Not Found',
                'errorCode': ErrorsService.resolve({'errorCode': 'NOT_FOUND'})
            });
        });
    });
