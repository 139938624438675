angular.module('BillPay').service('ProvidersService', function($rootScope, $resource, $q, _, endpoints, ProviderModel, Cache, $log){

    var _requestCache = new Cache('provider-request'),
        Providers = $resource(endpoints.providers.primary.url, null, {
            get: {
                method: 'GET',
                url: endpoints.providers.primary.url,
                params: {
                    urlSlug: '@urlSlug'
                }
            },
            getInvoice: {
                method: 'POST',
                url: endpoints.providers.getInvoice.url,
                params: {
                    urlSlug: '@urlSlug'
                }
            }
        });

    return {
        getProvider : function(urlSlug) {
            urlSlug = urlSlug ? urlSlug.toLowerCase() : $rootScope.providerSlug;

            var defer = $q.defer(), existingPromise = {};

            if (angular.isString(urlSlug) && urlSlug !== '') {
                urlSlug = urlSlug.toLowerCase();

                existingPromise = _requestCache.get(urlSlug);
                if(angular.isDefined(existingPromise)) {
                    return existingPromise.promise;
                }
                
                _requestCache.add(defer, urlSlug);
                Providers.get({urlSlug: urlSlug}).$promise.then(function(resp) {
                    if (resp && resp.hasData()) {
                        return new ProviderModel(resp.getData());
                    }
                    _requestCache.delete(urlSlug);
                    defer.reject();
                }).then(defer.resolve, defer.reject);

            } else {
                defer.reject({'status': 404});
            }

            return defer.promise;
        },

        getInvoice: function(urlSlug, memberId, invoiceNumber) {
            var defer = $q.defer();
            Providers.getInvoice({ urlSlug: urlSlug, invoiceNumber: invoiceNumber, memberId: memberId }).$promise.then(function(resp) {
                if (resp && resp.hasData()) {
                    return resp.getData();
                }
                defer.reject('notFound');
            }).then(defer.resolve, defer.reject);
            return defer.promise;
        }
    };
});
