angular.module("BillPay").config(["$translateProvider", function($translateProvider) {
$translateProvider.translations("en", {
    "receipt": {
        "receipt": "Receipt",
        "argPaidCallout": "<b>{{amount}}</b> Paid",
        "argScheduledCallOut": "<b>{{amount}}</b> Scheduled",
        "transactionIdArg": "Transaction ID #{{transactionId}}",
        "balancePayment": "Balance Payment",
        "payment": "Payment",
        "argDeclinedCallout": "<b>{{amount}}</b> Declined",
        "informationSecured": "Information Secured",
        "premiumPayment": "Premium Payment",
        "billTo": "Bill To:"
    },

    "landing": {
        "enterIdentifyingInfo": "Enter the unique details from your bill.",
        "enterIdentifyingInfoPremiumPayments": "Enter the unique details from your invoice.",
        "marketingBlurb": "Welcome to bill pay from {{providerName}}! We've partnered with Patientco to give you the simplest, most secure payment experience possible."
    },

    "actions": {
        "pay": "Pay",
        "makePayment": "Make A Payment",
        "continue": "Continue",
        "continueAnyway": "Continue Anyway",
        "processPayment": "Process Payment",
        "submit": "Submit",
        "retryProcessPayment" : "Retry Process",
        "processPaymentFuture" : "Schedule Payment",
        "payAnotherBill": "Pay Another Bill",
        "openReceipt": "View Receipt",
        "learnMore" : "Learn More",
        "close": "Close",
        "cancel": "Cancel",
        "visitWebsite": "Visit Website",
        "cancelPayment": "Cancel Payment",
        "sendFeedback": "Send Feedback",
        "sendingFeedback": "Sending...",
        "begin": "Get Started",
        "loading": "Loading...",
        "printReceipt": "Print Receipt",
        "dismissError": "Dismiss."
    },

    "labels": {
        "providerName": "Provider Name",
        "accountNumber": "Account Number",
        "accountNumberConfirm": "Confirm Account Number",
        "guarantorName": "Guarantor Name",
        "name": "Name",
        "firstName": "First Name",
        "lastName": "Last Name",
        "phone": "Phone Number",
        "dob": "Date of Birth",
        "email": "Email Address",
        "nameOnCard": "Name on Card",
        "cardNumber": "Card Number",
        "cvvFormat": "CVV",
        "expirationFormat": "MM / YY",
        "routingNumber": "Routing Number",
        "accountHolderName": "Account Holder's Name",
        "accountNumberArg": "Account # {{number}}",
        "memberIdArg": "Member ID {{number}}",
        "invoiceNumberArg": "Invoice # {{number}}",
        "address": "Street Address",
        "city": "City",
        "state": "State",
        "zip": "Zip Code",
        "postalCode": "Postal Code",
        "date": "Date",
        "description": "Description",
        "amount": "Amount",
        "balanceOnBill": "Amount Due",
        "enterUniqueDetails": "Enter details from your new bill.",
        "updating" : "Updating...",
        "loading" : "Loading...",
        "contactProvider": "Provider Contact",
        "argPaid": "{{amount}} Paid",
        "argScheduled": "{{amount}} Scheduled",
        "amountPaid": "Amount Paid",
        "details": "Details",
        "futurePayment": "Future Payment",
        "createdArg": "Created {{date}}",
        "termsPredicate": "I agree to the ",
        "termsLink": "Terms of Use",
        "declinedPayment": "Declined Payment",
        "paymentDeclined": "Payment Declined",
        "argDeclined": "{{amount}} Declined",
        "providerDisabledExplanation": "{{provider.name}} is no longer working with Patientco to accept online payments. For assistance in making a payment, please call {{provider.name}} at {{provider.number}}. We apologize for any inconvenience.",
        "providerDisabledExplanationShort": "{{provider.name}} is no longer working with Patientco to accept online&nbsp;payments.",
        "amountDue":"Pay Amount Due: {{amountDue}}",
        "submittingPayment": "Processing Payment...",
        "submittingPaymentFuture" : "Scheduling Payment...",
        "submitting" : "Submitting..."
    },

    "payment": {
        "amount": {
            "question" : "How much would you like to pay?",
            "stepName" : "Payment Amount",
            "payBillBalArg" : "Pay Bill Amount: <b>{{calculatedTotal}}</b>",
            "payBillBalArgDiscount" : "Pay Bill Amount with {{discountPolicy}}&nbsp;Discount:<br /><b>{{calculatedTotal}}</b>",
            "payAccountBalArg" : "Pay Account Balance: <b>{{calculatedTotal}}</b>",
            "payAccountBalArgDiscount" : "Pay Account Balance with {{discountPolicy}}&nbsp;Discount:<br /><b>{{calculatedTotal}}</b>",
            "payAnother" : "Pay Another Amount"
        },

        "date": {
            "question" : "When would you like to pay?",
            "stepName" : "Payment Date",
            "todayArg" : "Today: <b>{{todayDate}}</b>",
            "laterDate": "Pay At A Later Date"
        },

        "method": {
            "question" : "How would you like to pay?",
            "stepName" : "Payment Method",
            "cardMethod" : "Credit/Debit Card",
            "eCheckMethod" : "eCheck",
            "userAnotherCard" : "Use Another Card",
            "userAnotherECheck" : "Use Another eCheck",
            "userAnotherCardOrECheck" : "Use Another Card or eCheck"
        },

        "billing": {
            "question" : "What's your billing address for <br />{{methodSummary}}?",
            "stepName" : "Billing Address",
            "useAnotherAddress" : "Use Another Billing Address",
            "unitedStates": "United States",
            "international": "International"
        },

        "patientinfo": {
            "question" : "What are the patient details for this account?",
            "stepName" : "Patient Information"
        },

        "contact": {
            "question" : "Where should we send your receipt?",
            "stepName" : "Contact"
        },

        "confirm": {
            "question" : "Does everything look right?",
            "questionScheduledCart" : "Is this the payment method you’d like your provider to use?",
            "stepName" : "Confirmation",
            "cardBilling" : "Card Billing Address",
            "eCheckBilling" : "eCheck Billing Address",
            "scheduledCartEmailLabel" : "Send my receipt to this email"
        }
    },

    "dialogs" : {
        "thanksForPayment" : "Thank you for your payment!",
        "thanksForPaymentInfo" : "Thank you for your payment information!",
        "emailSentTo": "We've sent an email receipt to<br />{{email}}",
        "leavingPaymentFlow" : "Are you sure you want to leave this page? Your payment has not been processed.",
        "refreshingPaymentFlow" : "Are you sure you want to refresh this page? Your payment has not been processed and will be cleared.",
        "demoDialog": "Demo Environment",
        "gatewayDown": "We are currently experiencing difficulty processing payments.  We apologize for the inconvenience, please try again later.",
        "cardsHidden": "We are currently experiencing difficulty processing credit card payments. Please make your payment using an echeck.",
        "eChecksHidden": "We are currently experiencing difficulty processing echeck payments. Please make your payment using a credit card.",
        "cardsExpired": "At least one of your credit cards has expired, or will expire before the date your scheduled payment date.",
        "termsTitle": "Terms of Use",
        "retryProcessPayment" : "We were unable to fully process your payment. Please retry your submission below. Don't worry, we will only charge your card once.",
        "feedbackTitleReceiptLink": "How was your online payment experience?",
        "feedbackSubtitlePaymentHappy": "Awesome! Any other comments?",
        "feedbackSubtitlePaymentSad": "Sorry to hear that! How can we improve?",
        "feedbackCommentPlaceholder": "Start typing feedback...",
        "thanksForFeedback" : "Thank you for your feedback!"
    },

    "misc" : {
        "conjunction": "and",
        "footerText": "Powered by Patientco"
    },

    "meta" : {
        "title": "{{providerName}} | Pay Your Bill",
        "description": "Welcome to Billpay for {{providerName}}! The simplest, most secure healthcare payment experience possible."
    },

    "errors" : {
        "email" : "Please enter an email address that matches standard format. Here's an example: john.smith@example.com",
        "amountRequired": "Please enter the total balance printed on your bill. We'll ask for your desired payment amount in the next step.",
        "accountRequired": "Please enter an account number associated with your bill.",
        "memberIdRequired": "Please enter a {{memberIdLabel}} associated with your invoice.",
        "invoiceNumberRequired": "Please enter the {{invoiceNumberLabel}} associated with your invoice.",
        "accountInvalid": "The account number you've entered is invalid.",
        "memberIdInvalid": "The {{memberIdLabel}} you've entered is invalid.",
        "invoiceNumberInvalid": "The {{invoiceNumberLabel}} you've entered is invalid.",
        "accountInvalidProvider": "Account numbers for {{providerName}} {{accountNumberErrorMessage}}.",
        "belowMinAmount": "Please enter a total balance greater than or equal to {{minAmount}}",
        "zeroAmount": "Your payment amount must be greater than $0.00",
        "minAmountArg": "Your payment amount must be greater than or equal to {{minAmount}}",
        "maxAmountArg": "Your payment amount must be less than or equal to {{maxAmount}}",
        "amountGreaterThanReported": "Your payment amount is greater than the amount due reported on your statement ({{amountDue}}).",
        "dateRequired": "Please select a date from the calendar.",
        "ccRequired": "Please enter a credit or debit card number.",
        "ccSeemsInvalid": "Please enter a valid card number. You may have missing numbers or numbers that are out of order in your current input.",
        "ccUnknownCardType": "You've entered a card type that is not accepted. Try using a Visa, MasterCard, Discover, or American Express card.",
        "ccExpired": "Based on the date you've entered, your card has expired and cannot be charged.",
        "ccPrematureExpiration": "You've entered a card that expires before your payment date. Please use a card that expires after {{paymentExpMin}}.",
        "genericRequired": "Please complete this field to continue.",
        "invalidRoutingNumber": "The routing number you've entered is not valid.",
        "minimumAccountNumber": "Account numbers must be at least 4 digits.",
        "mismatchedAccountNumber": "The account numbers you've entered don't match. Please double check to make sure the numbers are exactly the same.",
        "paymentProcessingError" : "We are unable to process your payment. Please contact {{name}} at <span class='u-nowrap'>{{phone}}</span> to complete your payment.",
        "paymentInvalidCardInformation" : "The card information you inputted, is invalid. Please review the Card Number and Expiration for your payment method.",
        "paymentCardDeclined" : "We are unable to process your payment due to a declined card. Please edit your payment method and try again.",
        "paymentDuplicatePayment" : "It looks like you are trying to submit a duplicate payment. If this is intentional, please contact {{name}} at <span class='u-nowrap'>{{phone}}</span>.",
        "confirmExpiredCard": "It looks like you're trying to pay with a card that has expired, or will expire before your scheduled payment date. Please edit your payment method and try again.",
        "confirmPrematureExpiration": "It looks like you're trying to schedule a payment after your credit card expires. Please edit your payment method and try again.",
        "termsAcceptanceRequired": "For legal reasons, we require all users to accept our Terms of Use.",
        "minZipCode": "Please enter a 5 digit zip code to continue.",
        "fiveHundred": "It looks like the server encountered an error while processing your request. Please try again in a few minutes.",
        "invalidDate": "Date doesn't exist.",
        "futureBirthdate": "You've entered a DOB that takes place in the future.",
        "incompleteBirthdate": "Please enter a full DOB (MM/DD/YYYY).",
        "NOT_FOUND": "The page you have requested cannot be found. Please make sure you typed in the website address exactly as it's printed on your statement.",
        "PROVIDER_NOT_FOUND": "You've entered a provider name we're not familiar with. Double check to make sure that you've typed the website address exactly as it's printed on your statement.",
        "PROVIDER_SUSPEND_PROCESSING": "{{providerName}} is no longer processing payments through Patientco. For questions and concerns, please contact your provider directly at {{providerPhone}}.",
        "PROVIDER_NOT_YET_ACTIVE": "{{providerName}} is not yet ready to process payments through Patientco. For questions and concerns, please contact your provider directly at {{providerPhone}}.",
        "PROVIDER_INACTIVE": "{{providerName}} is no longer offering online bill pay through Patientco. For questions and concerns, please contact your provider directly at {{providerPhone}}.",
        "UNEXPECTED_ERROR": "Please contact Patientco at 1-844-422-4779 for assistance.",
        "unexpectedError": "Unexpected Error",
        "phoneFormat": "It looks like you are missing some numbers in your phone number. Please enter a number that matches the format of XXX-XXX-XXXX",
        "minimumCVV": "CVV must be at least 3 digits.",
        "oops": "Oops!",
        "paymentExpired": "Looks like the payment you're trying to complete is no longer waiting to be processed. Please contact your provider to check on the status of the payment or try again.",
        "urlExpired": "Your link has expired.",
        "invalidFeature": "Your provider does not have this feature enabled.",
        "tokenExpiredGuestPay": "Payment links expire after 1 hour.<br>Please <a href='{{patientWalletUrl}}'>click here</a> to go back and try again.",
        "tokenExpiredMobilePay": "Payment links expire after 1 hour.<br>Please contact your provider to try again.",
        "invalidFeaturePromptReturn": "Please <a href='{{patientWalletUrl}}'>click here</a> to return to your PatientWallet.",
        "invalidFeaturePromptContact": "Please contact your provider for assistance.",
        "tokenExpiredGeneric": "Payment links expire after 1 hour.",
        "noBalance": "The {{invoiceNumberLabel}} you entered has a $0.00 balance. No payment is required at this time.",
        "notFound": "We cannot verify the account associated with this {{invoiceNumberLabel}}. Please contact your provider at {{phone}}.",
        "rateLimitExceeded": "Too many failed attempts. Please try again in 10 minutes."
    },

    "notifications": {
        "questionAboutInvoice": "For questions about your invoice, contact your insurance provider at {{phone}}."
    }
}
);
}]);
