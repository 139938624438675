angular.module('BillPay')
    .controller('PaymentContactCtrl', function ($scope, Payment) {

        $scope.emailAddress = Payment.emailAddress;

        $scope.setContactInfo = function (emailAddress) {
            if ($scope.forms.contactForm.$invalid) return;
            var goodToGo = false;

            if ($scope.isEcashiering && !emailAddress) {
                //Skip validation and clear out email
                Payment.emailAddress = '';
                goodToGo = true;
            } else {
                goodToGo = Payment.set('emailAddress', emailAddress);
            }

            if (goodToGo) {
                $scope.continue();
            }
        };

        $scope.emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
    });
