angular.module('BillPay')
    .controller('ErrorCtrl', function($scope, $stateParams, $translate, ProvidersService, $filter){

        var finishSetup = function() {
            $scope.status = $stateParams.status;
            $scope.statusText = $stateParams.statusText;

            $translate('errors.' + $stateParams.errorCode, $stateParams.additionalData).then(function (translation) {
                $scope.message = translation;
            });
        };


        if ($stateParams.isDefaultError) {
            ProvidersService.getProvider().then(function(provider) {
                if (!provider.billPayEnabled && (provider.guestPayEnabled || provider.mobilePayEnabled)) {
                    $stateParams.status = $filter('translate')('errors.oops');
                    $stateParams.statusText = $filter('translate')('errors.invalidFeature');
                    $stateParams.errorCode = provider.guestPayEnabled ? 'invalidFeaturePromptReturn' : 'invalidFeaturePromptContact';
                    $stateParams.additionalData = provider;
                }
            }).finally(function() {
                finishSetup();
            });
        } else {
            finishSetup();
        }
    });
