angular.module('BillPay')
    .factory('PaymentModel', function (CoreModel, PaymentFormModel,
                                    PAYMENT_STATE, PAYMENT_METHOD, ServerStatusService, moment, _) {

        return CoreModel('payment', {

            deserialize: function (data) {
                this.transactionId = data.transactionId;
                this.status = data.status;

                this.accountNumber = data.accountNumber;
                this.subAccountNumber = data.subAccountNumber;

                this.amountDue = data.amountDue;
                this.providerSlug = data.provider ? data.provider.urlSlug : data.providerSlug;

                this.amount = data.amount;

                this.cartAmount = data.cartAmount;

                var paymentDate = (data.dateScheduled || data.dateProcessed) || data.paymentDate;
                if (angular.isDefined(paymentDate)) {
                    this.paymentDate = moment(paymentDate, 'YYYY-MM-DD').isValid() ?
                                       moment(paymentDate, 'YYYY-MM-DD') : moment(paymentDate);
                }

                this.method = data.method;
                this.paymentForm = data.paymentForm instanceof PaymentFormModel
                                    ? data.paymentForm : new PaymentFormModel(data.paymentForm);
                this.isInternational = data.isInternational;
                this.streetAddress = data.streetAddress;
                this.zip = data.zip;
                this.postalCode = data.postalCode;
                this.emailAddress = data.emailAddress;
                this.completed = data.completed;

                this.patientFirstName = data.patientFirstName;
                this.patientLastName = data.patientLastName;
                this.patientPhone = data.patientPhone;
                if (angular.isDefined(data.patientDOB)) {
                    this.patientDOB = moment(data.patientDOB, 'YYYY-MM-DD').isValid() ?
                        moment(data.patientDOB, 'YYYY-MM-DD') : moment(data.patientDOB);
                }

                this.voucherNumber = data.voucherNumber;
            },

            serialize: function () {
                return {
                    id: this.id,
                    isEcashiering: this.isEcashiering,
                    isGuestPay: this.isGuestPay,
                    isPremiumPayment: this.isPremiumPayment,
                    savePaymentForm: this.savePaymentForm,
                    accountNumber: this.accountNumber,
                    subAccountNumber: this.subAccountNumber,
                    amountDue: this.amountDue,
                    providerSlug: this.providerSlug,
                    amount: this.amount,
                    cartAmount: this.cartAmount,
                    paymentDate: this.paymentDate ? this.paymentDate.format('YYYY-MM-DD') : undefined,
                    method: this.method,
                    paymentForm: this.paymentForm.serialize(),
                    streetAddress: this.streetAddress,
                    isInternational: this.isInternational,
                    zip: this.zip,
                    postalCode: this.postalCode,
                    emailAddress: this.emailAddress || '',
                    completed: this.completed,
                    patientFirstName: this.patientFirstName,
                    patientLastName: this.patientLastName,
                    patientPhone: this.patientPhone,
                    patientDOB: this.patientDOB ? this.patientDOB.format('YYYY-MM-DD') : undefined,
                    voucherNumber: this.voucherNumber
                };
            },

            getValidatedFields: function () {
                var fields;
                if (this.isEcashiering) {
                    fields = ['providerSlug', 'accountNumber', 'amountDue', 'amount',
                    'paymentDate', 'method', 'paymentForm','savePaymentForm','userId'];
                    //emailAddress is optional, so only validate it if it's set
                    if (this.emailAddress) {
                        fields.push('emailAddress');
                    }
                    return fields;
                } else if (this.isGuestPay) {
                    fields = ['providerSlug', 'accountNumber', 'amountDue', 'amount',
                    'paymentDate', 'method', 'paymentForm', 'streetAddress',
                    'emailAddress'];
                } else if (this.isPremiumPayment) {
                    fields = ['providerSlug', 'accountNumber', 'subAccountNumber', 'amountDue', 'amount',
                    'paymentDate', 'method', 'paymentForm', 'streetAddress',
                    'emailAddress', 'voucherNumber'];
                } else {
                    fields = ['providerSlug', 'accountNumber', 'amountDue', 'amount',
                    'paymentDate', 'method', 'paymentForm', 'streetAddress',
                    'emailAddress', 'patientFirstName', 'patientLastName', 'patientPhone',
                    'patientDOB'];
                }

                if (!this.isInternational) {
                    fields.push('zip');
                }
                return fields;
            },

            // Validations

            validateAccountNumber: function (val, payment) {
                return angular.isString(val) && val.length > 0;
            },

            validateAmountDue: function (val, payment) {
                return angular.isNumber(val) && val > 0;
            },

            validateProviderSlug: function (val, payment) {
                return angular.isString(val) && val !== '';
            },

            validateAmount: function (val, payment) {
                return angular.isNumber(val) && val > 0;
            },

            validateCartAmount: function (val, payment) {
                return angular.isNumber(val) && val > 0;
            },

            validatePaymentDate: function (val, payment) {
                return moment.isMoment(val) && val.isValid() && val.diff(moment(), 'days') >= 0;
            },

            validateMethod: function (val, payment) {
                return angular.isString(val) && !!~_.values(PAYMENT_METHOD).indexOf(val)
                        && ServerStatusService.getPaymentStatus(val);
            },

            validatePaymentForm: function (val, payment) {
                return angular.isObject(val) && val instanceof PaymentFormModel
                    && (!payment || payment.method === val.formType)
                    && (!val.formType || ServerStatusService.getPaymentStatus(val.formType))
                    && val.isValid(['firstName', 'lastName'])
                    && ((val.isCredit() && val.isValid(['cardType', 'cardNumber', 'expDate'])
                        && (!payment || !payment.expiringBeforePaymentDate(val.expDate)))
                    || (val.isECheck() && val.isValid(['routingNumber', 'accountNumber'])));
            },

            validateStreetAddress: function (val, payment) {
                return angular.isString(val) && val !== '';
            },

            validateZip: function (val, payment) {
                return angular.isString(val) && val.length === 5;
            },

            validateIsInternational: function () {
                return true;
            },

            validatePostalCode: function () {
                return true;
            },

            validateEmailAddress: function (val, payment) {
                return angular.isString(val) && _.includes(val, '@');
            },

            validatePatientFirstName: function (val, payment) {
                return angular.isString(val) && val !== '';
            },

            validatePatientLastName: function (val, payment) {
                return angular.isString(val) && val !== '';
            },

            validatePatientPhone: function (val, payment) {
                return angular.isString(val) && val !== '';
            },

            validatePatientDOB: function (val, payment) {
                return moment.isMoment(val) && val.isValid() && val.diff(moment(), 'days') < 0;
            },

            // Util

            payingAmountDue: function () {
                return angular.isDefined(this.amount) && this.amount === this.amountDue;
            },

            payingToday: function () {
                if (angular.isUndefined(this.paymentDate)) {
                    return false;
                }
                return moment().diff(this.paymentDate, 'days') === 0;
            },

            expiringBeforePaymentDate: function (expDate) {
                return angular.isString(expDate) && !this.payingToday() && this.validate('paymentDate')
                        && moment(expDate, 'MMYY').add(1, 'month').isBefore(this.paymentDate);
            },

            clearPaymentForm: function () {
                delete this.method;
                this.paymentForm = new PaymentFormModel();
            },

            isCompleted: function () {
                return !!this.completed;
            }

        });
    });
