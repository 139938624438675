angular.module('BillPay')
    .controller('PaymentPatientInfoCtrl', function ($scope, Payment, $log, moment) {

        $scope.patientInfo = {
            firstName: Payment.patientFirstName,
            lastName: Payment.patientLastName,
            phone: Payment.patientPhone,
            dob: Payment.patientDOB ? Payment.patientDOB.format('MM/DD/YYYY') : undefined
        };

        $scope.setPatientInfo = function (patientInfo) {
            $log.info(patientInfo);
            if ($scope.forms.patientInfoForm.$invalid) return;

            if (Payment.set('patientFirstName', patientInfo.firstName) &&
                Payment.set('patientLastName', patientInfo.lastName) &&
                Payment.set('patientPhone', patientInfo.phone) &&
                Payment.set('patientDOB', moment(patientInfo.dob))) {
                $scope.continue();
            }
        };
    });
