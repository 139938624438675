angular.module('BillPay')
    .controller('PaymentConfirmCtrl', ['$scope', '$rootScope', '$filter', 'ProviderPaymentService', 'ErrorsService', 'AppStates', 'Config', 'vcRecaptchaService', 'ProvidersService', function ($scope, $rootScope, $filter, ProviderPaymentService, ErrorsService, AppStates, Config, recaptcha, ProvidersService) {

        var submitting = false,
            defaultConfirm = $filter('translate')('actions.' + ($rootScope.isScheduledCart ? 'submit' : 'processPayment'));

        $scope.gatewayRetryRequested = false;
        $scope.acceptedTerms = false;
        $scope.confirmDialog = defaultConfirm;
        $scope.processingNotifications = {};
        $scope.question = $filter('translate')('payment.confirm.question' + ($rootScope.isScheduledCart ? 'ScheduledCart' : ''));
        $scope.emailLabel = $filter('translate')($rootScope.isScheduledCart ? 'payment.confirm.scheduledCartEmailLabel' : 'labels.email');

        $scope.widgetId = null;
        $scope.recaptchaResponse = null;
        $scope.payment = null;
        $scope.recaptchaKey = Config.recaptchaKey;

        ProvidersService.getProvider().then(function (Provider) {
            $scope.Provider = Provider;
        });

        $scope.setWidgetId = function (widgetId) {
            $scope.widgetId = widgetId;
        };

        $scope.setRecaptchaResponse = function (response) {
            $scope.recaptchaResponse = response;
            $scope.makePayment($scope.payment);
        };

        $scope.recaptchaExpiration = function() {
            recaptcha.reload($scope.widgetId);
        };

        $scope.submitForm = function (payment) {
            $scope.payment = payment;

            if (!$scope.acceptedTerms)  {
                $scope.processingNotifications.tosRequired = true;
                $scope.recaptchaExpiration();
                return;
            }

            $scope.processingNotifications.tosRequired = false;
            
            if ($rootScope.isEcashiering || $rootScope.isPremiumPayment) {
                $scope.makePayment($scope.payment);
            } else {
                recaptcha.execute($scope.widgetId);
            }
        };

        $scope.makePayment = function (payment) {
            if (!submitting && payment.isValid()) {

                $scope.processed = submitting = true;

                $scope.confirmDialog = $filter('translate')('labels.submitting' + 
                    ($rootScope.isScheduledCart ? '' : (payment.payingToday() ? 'Payment' : 'PaymentFuture')));

                ProviderPaymentService.processProviderPayment(payment, $scope.recaptchaResponse, $scope.gatewayRetryRequested, $rootScope.token).then(
                    function (payment) {
                        if ($rootScope.isEcashiering && angular.isString($rootScope.webhook) && $rootScope.webhook !== '' && $rootScope.webhook !== 'undefined') {
                            $scope.redirect($rootScope.webhook);
                        } else {
                            $scope.goToState(AppStates.receipt.view, { payment: payment });
                        }
                    },
                    function (error) {
                        $scope.gatewayRetryRequested = submitting = false;
                        $scope.confirmDialog = error === ErrorsService.errors.GATEWAY_RETRY_REQUESTED ?
                            $filter('translate')('actions.retryProcessPayment') :
                            defaultConfirm;

                        switch (error) {
                            case ErrorsService.errors.GATEWAY_RETRY_REQUESTED:
                                $scope.gatewayRetryRequested = true;
                                $scope.processingNotifications.retryProcess = true;
                                break;
                            case ErrorsService.errors.METHOD_UNAVAILABLE:
                                $scope.processingNotifications[payment.method + 'Unavailable'] = true;
                                payment.deletePaymentForm();
                                break;
                            case ErrorsService.errors.PAYMENT_DECLINED:
                                $scope.processingNotifications.declinedCard = true;
                                break;
                            case ErrorsService.errors.PREMATURE_EXPIRATION:
                                $scope.processingNotifications.prematureExpiration = true;
                                break;
                            case ErrorsService.errors.PAYMENT_INVALID_CARD_NUMBER:
                            case ErrorsService.errors.PAYMENT_CARD_EXPIRED:
                                $scope.processingNotifications.invalidCardInformation = true;
                                break;
                            case ErrorsService.errors.PAYMENT_DUPLICATE:
                                $scope.processingNotifications.duplicatePayment = true;
                                break;
                            default:
                                $scope.processingNotifications.processingError = true;
                                break;
                        }
                    }
                );
            } else if (!payment.isValid()) {
                $scope.goToNextIncompleteState();
            }
        };

        $scope.editStep = function (step) {
            if (!$rootScope.isEcashiering) {
                if (!($rootScope.isPremiumPayment && (step === AppStates.payment.date))) {
                    $scope.goToState(step);
                }
            }
        };

    }]);
